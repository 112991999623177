.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
}

.news-grid-container {
  width: 100%;
  /* margin-top: 20px; */
}

.news-item {
  position: relative;
  /* border-radius: 0.5rem; */
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease-in-out;
}

.news-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.news-item img {
  width: 100%;
  height: 210px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.news-item:hover img {
  transform: scale(1.05);
}

.news-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), transparent);
  color: white;
}

.category {
  font-size: 0.75rem;
  background-color: #A9892D;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 0;
  margin-bottom: 0.5rem;
  display: inline-block;
}

/* .category.purple {
  background-color: #9333ea;
}
.category.blue {
  background-color: #3b82f6;
}
.category.red {
  background-color: #ef4444;
}
.category.green {
  background-color: #22c55e;
} */

.date {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.news-grid-container .title {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  text-align: start;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent); */
  padding: 0.5rem 1.4rem;
  display: inline-block;
  margin-bottom: -4px;
  width: 115%;
  margin-left: -20px;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
  text-overflow: ellipsis;
  padding-right: 35px;

}

.news-item:hover {
  cursor: pointer;
}

@media (max-width: 1512px) {
 
  .news-item img {
    
    height: 170px;
  }

  
}

@media (max-width: 1280px) {
 
  .news-item img {
    
    height: 140px;
  }

  
}

@media (max-width: 1024px) {
 
  .news-item img {
    
    height: 160px;
  }

  
}

@media (max-width: 820px) {
 
  .news-item img {
    
    height: 180px;
  }

}
@media (min-width: 1025px) {
  .news-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media( max-width:431px){
  .news-grid-container .title h3{
    font-size: 12px !important;
  }
  
  .news-grid {
    grid-template-columns: 1fr;
  }
  .news-item img {
    
    height: 240px;
  }
  }


