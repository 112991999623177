.category-page-top-left-container {
    border-right: 1px solid rgb(209, 209, 209);
    padding-top: 30px;
    padding-bottom: 60px;
    text-align: start;
}

.category-page-top-right-container {
    padding-top: 30px;
    padding-bottom: 60px;
    text-align: start;
}

.category-news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.category-news-grid3 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.category-news-grid-container {
    width: 100%;
}

.category-news-item {
    position: relative;
    /* border-radius: 0.5rem; */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.3s ease-in-out;
    z-index: 0 !important;
}

.category-news-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.category-news-item img {
    width: 100%;
    height: 165px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.category-news-item:hover img {
    transform: scale(1.05);
}

.category-news-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    color: white;
}

.category {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    /* border-radius: 9999px; */
    margin-bottom: 0.5rem;
    display: inline-block;
}

.category.purple {
    background-color: #9333ea;
}

.category.blue {
    background-color: #3b82f6;
}

.category.red {
    background-color: #ef4444;
}

.category.green {
    background-color: #22c55e;
}

.date {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
}

.category-news-grid-container .title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    padding: 0.5rem 1rem;
    display: inline-block;
    margin-bottom: -6px;
    width: 115%;
    margin-left: -20px;
    height: 3em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    text-overflow: ellipsis;
}

.category-news-item:hover {
    cursor: pointer;
}

.category-page-load-more-button {
    font-weight: 700;
    color: white;
    transition: all 0.3s;
    background-color: #374140;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
}

.category-page-load-more-button:hover {
    background-color: #AE8D2F;
}

@media (max-width: 1512px) {

    .category-news-item img {

        height: 120px;
    }

    .category-news-grid-container .title {
        font-size: 15px;
    }


}

@media (max-width: 1280px) {

    .category-news-item img {

        height: 100px;
    }

    .category-news-grid-container .title {
        font-size: 13px;
    }


}

@media (max-width: 1024px) {

    .category-page-container,
    .category-page-top-container,
    .category-page-bottom-container {
        padding-left: 90px;
        padding-right: 90px;
    }

    .category-news-item img {

        height: 120px;
    }
}

@media (max-width: 820px) {
    .category-page-container {
        padding-left: 80px;
        padding-right: 80px;
    }

    .category-page-top-container {
        grid: unset;
        padding: 0;
        column-span: unset;
    }

    .category-page-top-left-container {
        border: none;

    }

    .category-page-top-right-container {
        padding: 0;
        grid: unset;
        margin-bottom: 20px;
        grid-column: 1
    }

    .category-page-top-left-container {
        padding-right: 0;
        grid-column: 1
    }

    .category-news-item img {

        height: 180px;
    }

    .category-news-grid-container .title {
        font-size: 15px;
    }
}


@media (min-width: 769px) and (max-width: 1024px) {
    .category-news-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1025px) {
    .category-news-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    .category-news-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .category-news-item img {
        height: 120px;

    }

    .category-news-grid3 {
        grid-template-columns: repeat(2, minmax(0, 1fr));


    }

    .category-page-container,
    .category-page-top-container,
    .category-page-bottom-container {
        padding-left: 15px;
        padding-right: 15px;
        border: none;
        padding-bottom: 20px;
    }


    .category-page-top-left-container {
        border: none;
    }

    .category-news-item img {

        height: 200px;
    }
}

@media (max-width: 480px) {

    .category-news-item img {

        height: 120px;
    }

    .category-news-grid-container .title {
        font-size: 15px;
    }


}