.profile-page-container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.profile-page-bottom-left-container,
.profile-page-bottom-right-container {
    border-radius: 10px;
    background-color: rgb(247, 247, 247);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.profile-picture-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(203, 203, 203);
}

.profile-picture-div {
    height: 150px;
    width: 150px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 100%;
    margin-bottom: 15px;
}

.profile-picture {
    height: 150px;
    width: 150px;
    border-radius: 100%;
}

.custom-file-upload {
    
    color: white;
    font-weight: 600;
    background-color: #363F3E;
    transition: all 0.2s;
}

.custom-file-upload:hover {
  background-color: #AE8D2F;
}

.profile-page-tabs {
    flex-direction: column !important;
    row-gap: 0px !important;
}

.profile-page-tabs>.nav-item>button {
    color: rgb(85, 85, 85);
}

.profile-page-title1 {
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 15px;
}

.profile-page-title2 {
    font-size: 16px;
    font-weight: 600;
}


.profile-page-input {
    background-color: #e0e0e0;
    height: 40px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 18px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.profile-page-submit-button {
    
    color: white;
    font-weight: 600;
    background-color: #363F3E;
    transition: all 0.2s;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .profile-page-submit-button:hover {
    background-color: #AE8D2F;
  }

  .profile-page-input-message {
    background-color: #e0e0e0;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 18px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
  }

  .profile-page-sub-card {
    display: flex;
    align-items: center;
    gap: 10px;
    transition: transform 0.3s ease;
  }
  
  .profile-page-sub-card:hover {
    transform: scale(1.01);
  }
  
  .profile-page-sub-card-image {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
  }

  .profile-page-sub-card-title {
    flex: 1;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
  }

  .profile-page-sub-card-title:hover {
    color: #FD5927;
  }
  
  .profile-page-sub-card-text {
    flex: 1;
    text-align: left;
    font-size: 15px;
  }

  @media (max-width: 1024px) {
    .profile-page-container{
     padding-left: 150px;
     padding-right: 150px;
    } 
 }

 @media (max-width: 820px){
    .profile-page-container{
        padding-left: 80px;
        padding-right: 80px;
       } 

       .profile-page-bottom-container{
        grid: unset;
       }

       .profile-page-bottom-left-container{
        grid-column: span 7;
       }

}

  @media (max-width: 768px) {
    .profile-page-container{padding-left: 15px;
    padding-right: 15px;}
  

  /* .profile-page-tabs-web{
    display: none;
  } */

  .profile-page-bottom-left-container{
    margin-bottom: 20px;
  }

  /* .profile-page-bottom-right-container{
    margin-top: 20px;
  } */

  .profile-picture-container{
    padding-top: 20px;
  }

}