.approval-page-container {
    padding: 40px 300px;
    height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    
}

.approval-page-text {
    font-size: 35px;
}

.approval-page-logo {
    width: 450px;
}

@media (max-width: 2560px) {
    .approval-page-container {
        padding: 40px 300px;
        height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 1920px) {
    .approval-page-container {
        padding: 40px 300px;
        height: 850px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 1512px) {
    .approval-page-container {
        padding: 40px 300px;
        height: 720px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 30px;
    }
    
    .approval-page-logo {
        width: 400px;
    }
}

@media (max-width: 1280px) {
    .approval-page-container {
        padding: 40px 300px;
        height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 30px;
    }
    
    .approval-page-logo {
        width: 375px;
    }
}

@media (max-width: 1200px) {
    .approval-page-container {
        padding: 40px 200px;
        height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 27px;
    }
    
    .approval-page-logo {
        width: 350px;
    }
}

@media (max-width: 1024px) {
    .approval-page-container {
        padding: 40px 200px;
        height: 550px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 27px;
    }
    
    .approval-page-logo {
        width: 300px;
    }
}

@media (max-width: 900px) {
    .approval-page-container {
        padding: 40px 200px;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 24px;
    }
    
    .approval-page-logo {
        width: 275px;
    }
}

@media (max-width: 840px) {
    .approval-page-container {
        padding: 40px 200px;
        height: 620px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 24px;
    }
    
    .approval-page-logo {
        width: 250px;
    }
}

@media (max-width: 820px) {
    .approval-page-container {
        padding: 40px 50px;
        height: 660px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 24px;
    }
    
    .approval-page-logo {
        width: 275px;
    }
}

@media (max-width: 430px) {
    .approval-page-container {
        padding: 40px 30px;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }

    .approval-page-text {
        font-size: 20px;
    }
    
    .approval-page-logo {
        width: 220px;
    }
}