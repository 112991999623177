/* currencytable.css */

.currency-table-head {
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 15px;
  text-align: center;
  background-color: #374140;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
}

.currency-table-body tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.currency-table-body tr:nth-child(even) {
  background-color: #ffffff;
}

.positive {
  color: green;
}

.negative {
  color: red;
}

@media (max-width: 1280px) {
  th,
td {
  padding: 5px;
  text-align: left;
}
}