.kny {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 0% 250px;
  /* margin-bottom: 5%; */
}
.kny .member {
   background-color: #fff;/*5f5f5f */
  padding: 2%;
  /* box-shadow: rgba(0, 0, 0, 0.101) 0px 5px 15px; */
}
.kny .member .row {
  flex-direction: column;
  padding: 1%;
}
.kny .member .row .containerBox {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.271) 0px 5px 15px;
}
.kny .member .row .col-1 .row {
  width: 100%;
  height: 100%;
  padding: 1%;
  display: flex;
  flex-direction: row;
}

.kny .contentBox {
  object-fit: cover;
}

.kny .contentBox h6 {
  font-weight: bold;
  font-size: large;
}
/* .kny .kunyeContent {
  object-fit: cover;
  width: 100%;
  height: 100%;
} */
.kny .mapIcon {
  font-size: 30px;
  margin-top: 0%;
}
.kny .knyTitle{
    font-size: xx-large;
    font-weight: bold;
}
@media (max-width: 1920px) {
  .kny {
    margin-top: -0%;
  }
}
@media (max-width: 1280px) {
  .kny .h6 {
    font-size: medium !important;
  }
  .kny .kunyeContent {
    font-size: 14px;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 img {
    width: 80% !important;
  }
}
@media (max-width: 1200px) {
  .kny .h6 {
    font-size: small !important;
  }

  .kny .kunyeContent {
    font-size: 11px;
  }
}
@media (max-width: 1024px) {
  .kny {
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
  }
  .kny .member {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kny .kunyeContent {
    font-size: 12px;
  }
  .kny .icon {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  .kny {
    height: 100%;
    padding-left: 150px;
    /* margin-bottom: 0%; */
  }
  .kny .member {
    width: 550px;
  }
  .kny .member .row {
    margin-left: 1%;
  }
  .kny .h6 {
    font-size: small !important;
  }
  .kny .kunyeContent {
    font-size: 12px;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 {
    width: 50%;
    padding: 0;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 img {
    width: 70% !important;
  }
  .kny .contentBox {
    padding-top: 1%;
  }
}
@media (max-width:835px){
.kny{
  padding: 5% 50px;
}
}
@media (max-width: 820px) {
  .kny {
    height: 100%;
    padding: 10px 30px;
    /* margin-bottom: 0%; */
  }
  .kny .member {
    width: 100%;
  }
  .kny .h6 {
    font-size: small !important;
  }
  .kny .kunyeContent {
    font-size: 11px;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 {
    width: 43%;
    padding: 0;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-6 {
    width: 57%;
  }
  .kny .member .row .col-1 .row .col-6 .row .col-5 img {
    width: 70% !important;
  }
  .kny .mapIcon {
    font-size: 34px;
    margin-top: -5%;
  }
}
@media (max-width: 768px) {
  .kny {
    height: 100%;
  }
  .kny .member {
    width: 110% !important;
    margin-left: -6%;
    margin-top: -4%;
  }
  .kny .mapIcon {
    font-size: 20px;
    margin-top: -1%;
  }
}
@media (max-width: 576px) {
  .kny {
    height: 100%;
    margin-top: 4%;
  }
  .kny .member {
    margin-left: 0;
    width: 100% !important;
    /* margin-left: -120px !important; */
  }
  .kny .mapIcon {
    font-size: 34px;
    margin-top: -5%;
  }

  /* .kny .member .row {
    width: 420px !important;
  } */
  
}
@media (max-width: 430px) {
  .kny {
    width: 100%;
    padding-left: 1%;
    height: 100%;
  }

  .kny .container {
    width: 420px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -2% !important;
  }
  .kny .member .row {
    width: 370px !important;
  }
  .kny .kunyeContent {
    font-size: 9px;
  }
  .kny .col-5 {
    margin-left: -5%;
  }
  .kny .col-5 img{
    padding: 0 !important;
    padding-top: auto;
  }
  .kny .mapBox {
    margin-left: 0%;
    height: 120px;
  }
  .kny .kunyeTitle{
    font-size: 12px !important;
  }
  
}

@media (max-width: 410px) {

  .kny .member .row {
    width: 350px !important;
  }
  .kny .mapBox {
    margin-left: 0%;
    height: 100px;
  }
}


@media (max-width: 393px) {

  .kny .member .row {
    width: 350px !important;
  }
  .kny .mapBox {
    margin-left: 0%;
    height: 100px;
  }
}


@media (max-width: 390px) {
  .kny {
    width: 100%;
    padding-left: 1%;
    height: 100%;
  }

  .kny .container {
    width: 380px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: -5% !important; */
  }
.kny .col-6 .row{
  margin-left: -5%;
}
.kny .mapBox {
  margin-left: -0%;
}

.kny .member .row {
  width: 350px !important;
}

}

@media (max-width: 360px) {
  .kny {
    width: 100%;
    padding-left: 1%;
    height: 100%;
  }

  .kny .container {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2% !important;
  }
.kny .col-6 .row{
  width: 100% !important;
  margin-left: -5%;
}

.kny .member .row {
  width: 350px !important;
}
}

@media (max-width: 340px) {

  .kny .member{
    margin-left: 2% !important;

  }
  .kny .member .row {
    width: 310px !important;

  }

}

@media (max-width: 320px) {

  .kny .member{
    margin-left: 5% !important;

  }
  .kny .member .row {
    width: 310px !important;
 }
  .kny .kunyeTitle{
    font-size: 9px !important;
  }
  .kny .kunyeContent{
    font-size: 8px;
  }
}
