.sg-carousel-container {
    margin-top: 30px;
    height: auto;
    margin-bottom: 70px;
    overflow: hidden; 
    position: relative; 
}

.sg-carousel-inner {
    display: flex; 
    transition: transform 0.5s ease-in-out; 
    width: 100%; 
}

.sg-top-carousel-item {
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    flex-shrink: 0;
    box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -40px inset !important;
}


.sg-top-carousel-caption h5 {
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    padding: 10px;
    width: 100%;
    font-size: 35px;
    font-weight: 500;
    box-sizing: border-box;
    height: 2.9em;
    text-align: start;
    position: absolute;
    bottom: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2em;
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    padding: 5px;
}

.carousel-control-prev {
    left: 10px;
}

.carousel-control-next {
    right: 10px;
}

.sg-carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
}

.sg-carousel-indicators button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: white;
    border: none;
    cursor: pointer;
}

.sg-carousel-indicators button.active {
    background-color: grey;
}

.slide-title {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #C90913 ;
    color: white;
    padding: 5px 10px;
    font-size: 22px;
    font-weight: bold;
}

.sg-carousel-page-number-buttons {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.page-button {
    margin: 0 5px;
    padding: 2px 0;
    width: 30px;
    color: white;
    font-weight: 700;
    /* border: none; */
    /* background-color: #ccc; */
    cursor: pointer;
    /* border-radius: 5px; */
    /* transition: all 0.2s; */
    border-color: #AE8D2F;
}

.page-button:hover {
    border-bottom: 6px solid #AE8D2F;
}

.page-button.active {
    /* background-color: #555; */
    color: white;
    border-bottom: 6px solid #AE8D2F;
}

@media (max-width:1980px) {
    .sg-carousel-container{
        margin: 30px 250px !important;
    }
}

@media (max-width:1920px) {
    .sg-carousel-container{
        margin: 30px 230px !important;
    }
    .sg-top-carousel-item {
        height: 500px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        flex-shrink: 0; 
        box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -40px inset !important;
        position: relative !important; 
        z-index: 9;
    }
}

@media (max-width:1600px) {
    .sg-carousel-container{
        margin: 25px 140px !important;
    }
    .sg-top-carousel-item{
        height: 490px;
    }
    .sg-top-carousel-caption h5 {
        font-size: 32px;
    }
}

@media (max-width:1512px) {
    .sg-carousel-container{
        margin: 30px 160px !important;
    }
    .sg-top-carousel-item{
        height: 400px;
    }
    .sg-top-carousel-caption h5 {
        font-size: 30px;
    }
}

@media (max-width:1441px) {
    .sg-carousel-container{
        margin: 30px 125px !important;
    }
    .sg-top-carousel-item{
        height: 400px;
    }
    .sg-top-carousel-caption h5{
        font-size: 28px;
        bottom: 45px;
    }

    .page-button {
        margin: 0 5px;
        padding: 2px 0;
        width: 25px;
        font-size: 14px;
        color: white;
        font-weight: 700;
        border: none; 
    }
}
@media (max-width:1367px) {
    .sg-carousel-container{
        margin: 25px 105px !important;
    }
    .sg-top-carousel-item{
        height: 400px;
    }
    .sg-top-carousel-caption h5{
        font-size: 27px;
        bottom: 45px;
    }
}

@media (max-width:1280px) {
    .sg-carousel-container{
        margin: 30px 100px !important;
        margin-bottom: 40px !important;
    }
    .sg-top-carousel-item{
        height: 360px;
    }

    .sg-carousel-bottom-container{
        margin-top: 55px !important;
    }
    .sg-top-carousel-caption h5 {
        font-size: 25px;
        bottom: 45px;
    }
}

@media (max-width: 1024px) {

    .sg-carousel-container {
        margin: 30px 90px !important;
    }
.sg-carousel-bottom-left-container {
        margin-bottom: 30px;
    }

    .sg-top-carousel-item{
        height: 400px;
    }
}

@media (max-width: 991px) {

    .sg-carousel-container{
        margin: 30px 90px !important;
    }

    .sg-top-carousel-item{
        height: 390px;
    }

}

@media (max-width: 836px) {

    .sg-carousel-container{
        margin: 70px 90px !important;
    }

    .sg-carousel-bottom-left-container {
        margin-bottom: 30px;
    }
    .sg-top-carousel-item{
        height: 310px;
    }

    .page-button {
        margin: 0 3px;
        padding: 2px 0;
        width: 20px;
        font-size: 11px;
        color: white;
        font-weight: 700;
        border: none; 
    }
}

@media (max-width: 820px) {

    .sg-carousel-container {
        margin: 70px 5px !important;

        margin-top: 55px;
        
    }

    .sg-top-carousel-item{
        height: 380px;
    }
    .sg-carousel-bottom-left-container {
        margin-bottom: 30px;
    }

    .page-button {
        margin: 0 3px;
        padding: 2px 0;
        width: 23px;
        font-size: 12px;
        color: white;
        font-weight: 700;
        border: none; 
    }

}

@media (max-width: 768px) {

    .sg-carousel-container {
        margin: 70px 5px !important;

        margin-top: 55px;
        
    }
    .sg-top-carousel-item{
        height: 350px;
    }
    .sg-carousel-bottom-left-container {
        margin-bottom: 30px;
    }

    .slide-title {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #C90913 ;
        color: white;
        padding: 5px 10px;
        font-size: 18px;
        font-weight: bold;
    }

}


@media (max-width: 650px) {

    .sg-carousel-container {
        margin-left: 10px;
        margin-right: 10px;
    }

    .sg-carousel-bottom-left-container {
        margin-bottom: 15px;
        height: auto;
    }

    .sg-top-carousel-item {
        height: 360px;
        box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -90px inset !important;
    }

    .sg-top-carousel-caption h5 {
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
        bottom: 40px;
        height: 3em;
    }

    .sg-carousel-top-right-container {
        margin-bottom: 50px;
    }

}

@media (max-width: 567px) {

    .sg-carousel-container {
        margin: 70px 15px !important;
        margin-top: 55px;
        
    }
    .sg-top-carousel-item{
        height: 300px;
    }
    .sg-carousel-bottom-left-container {
        margin-bottom: 30px;
    }

}

@media (max-width: 480px) {

    .sg-carousel-top-mobile-container {
        display: unset;
    }

    .sg-carousel-top-container {
        display: none;
    }

    .sg-top-carousel-caption h5{
        bottom: 40px;
    }
  .sg-top-carousel-item{
          height: 255px !important;
      }

      .page-button {
        margin: 0 3px;
        padding: 2px 0;
        width: 18px;
        font-size: 10px;
        color: white;
        font-weight: 700;
        border: none; 
    }

    .page-button:hover {
        border-bottom: 3px solid #AE8D2F;
    }
    
    .page-button.active {
        /* background-color: #555; */
        color: white;
        border-bottom: 3px solid #AE8D2F;
    }

}

@media (max-width: 430px) {

    .sg-carousel-container {
        margin: 0px 5px !important;

        margin-top: 55px;
        
    }

    .sg-carousel-bottom-container{
        margin-top: 40px !important;
    }
    
    .sg-carousel-bottom-left-container {
        margin-bottom: 0px;
    }

    .sg-top-carousel-item{
        height: 235px !important;
    }

    .sg-top-carousel-caption h5{
        bottom: 35px;
    }

    .page-button {
        margin: 0 2px;
        padding: 2px 0;
        width: 16px;
        font-size: 11px;
        color: white;
        font-weight: 700;
        border: none; 
    }

}
