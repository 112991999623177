.run-text {
  overflow: hidden;
  padding: 0;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  font-size: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 100%;
}

.ticker {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  white-space: nowrap;
  padding-right: 100%;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 50000ms !important;
  animation-duration:100000ms !important;
}

.ticker__item {
  display: inline-block;
  padding: 0 2rem;
  color: #333;
  position: relative;
}

.ticker__item a {
  display: inline-block;
  color: #333;
  text-decoration: none;
  padding-right: 1rem;
}

.ticker__item a::after {
  content: "|";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  color: #333;
}

.ticker__item:last-child a::after {
  content: "";
}

.ticker:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ticker-news-text {
  position: absolute;
  left: 0;
  top: 0;
  background: #AE8D2F;
  color: #fff;
  padding: 6px 20px;
  z-index: 9;
  height: 42px;
  display: flex;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 100%);
}

.breaking {
  display: inline-block;
  animation: heartbeat 1.5s infinite;
  margin-right: 15px;
}

@keyframes heartbeat {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2); /* Büyüme */
  }
  60% {
    transform: scale(1.1); /* Küçülme */
  }
}

@media (max-width: 768px) {
  .ticker-wrap {
    font-size: 16px;
  }

  .ticker {
    padding: 3px;
    animation-duration:150000ms !important;
  }

  .ticker__item {
    padding: 0 1rem;
  }

  .ticker-news-text {
    padding: 4px 12px;
    margin-top: -5px;
  }
}

.run-text .ticker__item .dollar {
  color: green;
}

.run-text .ticker__item .euro {
  color: blue;
}

.run-text .ticker__item .pound {
  color: red;
}

.run-text .ticker__item .gold {
  color: goldenrod;
}

.run-text .ticker__item .bitcoin {
  color: orange;
}
