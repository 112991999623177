.news-page {
  font-family: Arial, sans-serif;
  max-width: 1500px;
  padding: 0px;
  height: auto;
  margin: 0 auto;
  margin-top: -20px;
}

.news-page .header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  
  border-bottom: 5px solid #A9892D;
  /* width: max-content; */
}

.news-page .mobtitle {
  display: none;
}

/* @media (max-width: 768px) {
  .mobtitle {
    display: block !important;
  }
} */

.news-page .dont-miss {
  background-color: #374140;
  color: white;
  padding: 10px 40px;
  font-weight: bold;
  position: relative;
  display: inline-block;
  margin-right: 4px;
}



.categories-nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 3px;
}

.news-page nav a {
  color: #333;
  text-decoration: none;
  margin-right: 15px;
  font-size: 16px;
  position: relative;
  padding: 10px 10px;
}

.news-page nav a.active {
  color: #f73c29;
  font-weight: bold;
}

.news-page nav a.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f73c29;
}

.news-page .content {
  
  gap: 12px;
}

.news-page .featured-article {
  
  position: relative;
  overflow: hidden;
}

.news-page .featured-article img {
  width: 100%;
  height: 430px;
  display: block;
  transition: all 0.3s ease;
}

.news-page .featured-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  padding: 20px;
}

.news-page .article-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 420px;
  transition: opacity 0.5s ;
  opacity: 1;
}

.news-page .article-list .row .articles-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news-page .fade-in {
  opacity: 1;
}

.news-page .fade-out {
  opacity: 0;
}

.navigation-mobile {
  display: none !important;
}
.navigation-desktop .nav-button-left {
  margin-bottom: 5px;
}

.news-page .article {
  /* display: flex; */
  cursor: pointer;
  transition: all 0.2s;
}

.news-page .article:hover{
  background-color: #374140;
  color: white;
}

.news-page .article-thumbnail {
  width: 140px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
}

.news-page .article-info {
display: flex;
  align-items: center;  
}


.news-page .article-info p {
  
  flex: 1;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    line-height: 1.4em; 
}



.news-page .navigation-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.news-page .navigation-buttons button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.content .navigation {
  display: flex;
  margin: 15px 7px 20px;
  line-height: 1;
  flex-direction: column;
  margin: auto;
}

.content .navigation > span {
  padding: 8px 8px;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  -moz-transition: background-color 300ms, color 300ms;
  -o-transition: background-color 300ms, color 300ms;
  -webkit-transition: background-color 300ms, color 300ms;
  background-color: #374140;
  color: white;
}

.content .navigation > span:hover {
  background-color: #A9892D;
  color: white !important;
}

.content .navigation > span:hover i {
  color: #A9892D;
  color: white;
}

.articles-content {
  padding-right: 0 !important;
  padding-left: 20px;
}


.header nav button {
  background-color: #343D3C;
  border: none;
  padding: 10px 0px;
  width: 100%;
  
  /* margin-left: 4px; */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header nav button:hover {
  background-color: #A9892D;
}

.header nav button.active {
  background-color: #A9892D;
  color: white;
}

.featured-article {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100%;   
  display: flex;
  align-items: flex-end;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.85) 0px -200px 80px -40px inset;
}

.featured-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Yarı saydam siyah örtü */
}

.featured-title {
  padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    font-size: 35px;
    font-weight: 600;
    text-align: start;
    width: 100%;
    left: unset;
    right: unset;
    line-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    line-height: 1.4em; 
}

.article-list {
  /* Diğer stiller */
}

.article-list.fade-in {
  animation: fadeIn 0.5s;
}

.article-list.fade-out {
  animation: fadeOut 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.article {
  /* display: flex; */
  
  cursor: pointer;
}





.navigation {
  display: flex
}

/* 
@media (max-width: 480px) {
  .news-page nav a {
    font-size: 15px;
    padding: 4px;
  }

  .news-page .featured-article img {
    height: 200px;
  }

  

  .news-page .article-info p {
    font-size: 12px;
  }

  .news-page .article-info p {
    font-size: 9px;
  }
} */




@media (min-width: 1024px) and (max-width: 1366px) {
  /* .mobtitle {
    display: block !important;
  } */
  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }
  .articles-content {
    padding-left: 13px;
  }
  /* .news-page .dont-miss::before {
    display: none;
  }
  .news-page .dont-miss::after {
    display: none;
  }*/
  

  .news-page .header {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 4px;
    height: 100%;
    padding: 10px 5px;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav a {
    font-size: 19px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article img {
    height: 300px;
  }

  .news-page .article-thumbnail {
    width: 80px;
    height: 60px;
  }

  .news-page .article-info p {
    font-size: 20px;
  }

  
  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }
  .navigation-desktop {
    display: none !important;
  }
}


@media (min-width: 834px) and (max-width: 1194px) {
  .mobtitle {
    display: block !important;
  }
  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }
  .articles-content {
    padding-left: 13px;
  }
  /* .news-page .dont-miss::before {
    display: none;
  }
  .news-page .dont-miss::after {
    display: none;
  }
  .news-page .dont-miss {
    display: none;
  } */

  .news-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 0;
    width: 100%;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav a {
    font-size: 15px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article {
    height: 300px;
    margin-bottom: 20px;
  }

  .news-page .article-thumbnail {
    width: 80px;
    height: 60px;
  }

  .news-page .article-info p {
    font-size: 17px;
  }

 
  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }
  .navigation-desktop {
    display: none !important;
  }

  .categories-nav-list button {
    margin: 3px;
  }

  .news-page .header{
    border: none;
  }
}


@media (min-width: 810px) and (max-width: 834px) and (orientation: landscape),
  (min-width: 810px) and (max-width: 834px) and (orientation: portrait) {
  
  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }
  .articles-content {
    padding-left: 13px;
  }
  /* .news-page .dont-miss::before {
    display: none;
  }
  .news-page .dont-miss::after {
    display: none;
  } */
  .news-page .dont-miss {
    width: 100%;
  }

  .news-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 0;
  }

  .categories-nav-list{
    flex-wrap: wrap;
    
  }

  .header nav button {
    width: max-content;
    padding: 10px 10px;
    margin: 5px 5px;
  }

  .categories-nav-list button {
    margin: 3px;
  }
  .news-page nav {
    justify-content: center;
  }

  .news-page nav a {
    font-size: 14px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article {
    height: 300px;
  }

  .article-list{
    margin-top: 20px;
  }
  .news-page .article-thumbnail {
    width: 120px;
    height: 80px;
  }

  .news-page .article-info p {
    font-size: 12px;
  }

  
  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }
  .navigation-desktop {
    display: none !important;
  }

  .news-page .header{
    border: none;
  }
}


@media (max-width: 768px) {
  .news-page {
    padding: 10px;
    margin-top: 2.2rem;
  }
  .articles-content {
    padding-left: 13px;
  }
  /* .news-page .dont-miss::before {
    display: none;
  }
  .news-page .dont-miss::after {
    display: none;
  }*/
  .news-page .dont-miss {
    width: 100%;
  } 

  .news-page .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-page .dont-miss {
    margin-right: 0;
  }

  .news-page nav {
    justify-content: center;
  }

  .news-page nav a {
    font-size: 14px;
    padding: 8px;
  }

  .news-page .content {
    flex-direction: column;
  }

  .news-page .featured-article {
    height: 250px;
  }

  .featured-title{
    font-size: 22px;
    padding: 0px 10px;
  }

  .news-page .article-thumbnail {
    width: 120px;
    height: 70px;
  }

  .article-list{
    margin-top: 20px;
  }

  .news-page .article-info p {
    font-size: 12px;
  }

  
  .navigation-mobile {
    display: flex !important;
    flex-direction: row !important;
    margin: 0 !important;
  }
  .navigation-desktop {
    display: none !important;
  }

  .categories-nav-list{
    flex-wrap: wrap;
  }

  .header nav button {
    width: max-content;
    padding: 10px 10px;
    margin: 5px 5px;
  }

  .news-page .header{
    border: none;
  }
}