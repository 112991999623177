.album-content-page-header {

    text-align: start;
}

.album-content-header-text {
    color: #d4d4d4;
    margin-top: 10px;
}

.album-content-title1 {
    font-size: 32px;
    color: #FFFFFF;
    font-weight: 600;
}

.album-content-container {
    border-top: 1px solid rgb(209, 209, 209);
}

.album-content-left-container {
    /* border-right: 1px solid rgb(209, 209, 209); */
    padding-top: 30px;
    padding-bottom: 60px;
    text-align: start;
}

.album-content-right-container {

    padding-top: 30px;
    padding-bottom: 60px;
    text-align: start;
}

.album-content-image {
    margin-bottom: 32px;
    width: 100%;
}

.share-link-container > a{
    height: 35px;
    width: 35px;
    font-size: 16px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%; 
    transition: all 0.15s;
}

.album-content-text {
    font-size: 14px;
    margin-bottom: 10px;
}

.album-content-title2 {
    font-size: 28px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
}

.album-content-author-image {
    width: 90px;
    height: 90px;
    border-radius: 100%;
}

.react-pdf__message{
    display: none;
}

@media (max-width: 1280px) {
    .page-link-container, .album-content-page-header, .album-content-container{
     padding-left: 180px;
     padding-right: 180px;
    } 
 }

@media (max-width: 1024px) {
   .page-link-container, .album-content-page-header, .album-content-container{
    padding-left: 180px;
    padding-right: 180px;
   } 
}

@media (max-width: 820px){
    .page-link-container, .album-content-page-header, .album-content-container{
        padding-left: 80px;
        padding-right: 80px;
       } 

       .album-content-container{
        grid: unset;
        grid-column: unset;
       
       }

       .album-content-left-container{
        border: none;
        padding-right: 0;
       }

       .album-content-right-container{
        padding-left: 0;
        grid-column: span 11 / span 11;
       }
}

@media (max-width: 768px) {
    .album-content-container {
        padding-left: 15px;
        padding-right: 15px;
        border: none;
    }

    .album-content-left-container {
        border: none;
    }

    .album-content-right-container {
        display: none;
    }

    .page-link-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .album-content-page-header {
        padding-left: 15px;
        padding-right: 15px;
    }

    .share-link-container > a{
        height: 22px;
        width: 22px;
        font-size: 12px;
        border-width: 1px;
    }

    .next-previous-content{
        margin-left: 6px;
        margin-right: 6px;
        font-size: 15px;
    }

    .album-page-cover{
        height: 250px;
        object-fit: cover;
    }

}