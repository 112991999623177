.trends {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-end;
  margin-right: -1px;
}

.more {
  display: flex;
  justify-content: end;
}

.trending {
  font-family: Arial, sans-serif;
}

.trending ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.trending li {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.trending .index {
  font-weight: bold;
  margin-right: 0.5em;
}

.trending .text {
  flex-grow: 1;
}

.trending .arrow {
  color: red;
}

.pop-news {
  font-size: 23px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}

.ads-trends {
  margin-top: 15px;
}

.ads-trends a img {
  /* width: 80% !important; */
  /* max-width: 300px; */
  margin: 0 auto;
}

@media (max-width: 768px) {
  .trends {
    align-items: center;
    margin-top: -15px;
    margin-right: 0;
  }

  .trending {
    /* width: 100%; */
    margin-top: 30px;
  }

  .ads-trends img {
    max-width: 80%;
    margin: 0px auto;
  }

  .pop-news {
    font-size: 20px;
  }

  .trending li {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .pop-news {
    font-size: 18px;
  }

  .trending li {
    font-size: 14px;
    padding: 0.5em 0;
  }

  .ads-trends img {
    max-width: 80%;
    margin: 0px auto;
  }
}

.trends .league-table {
  font-family: Arial, sans-serif;
  /* max-width: 410px; */
  /* padding: 15px; */
  margin: 0 auto;
  /* background-color: #fff; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.05); */
  margin-top: 20px;
}

.trends h2 {
  margin-top: 0;
  font-size: 18px;
}

.trends table {
  width: 100%;
  border-collapse: collapse;
}

.trends th,
td {
  padding: 10px 5px;
  text-align: left;
}

.trends th {
  font-weight: normal;
  color: #777;
}

.trends tr {
  border-bottom: 1px solid #eee;
}

.trends tr:last-child {
  border-bottom: none;
}

.trends .expand {
  text-align: center;
  color: green;
  padding: 10px 0;
  cursor: pointer;
}

.trends .view-table {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}
