.popUpAds {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);
  z-index: 999;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-top: 8px solid rgb(234, 234, 234);
}

.popUpAds.open {
  transform: translateY(0);
}

.popUpAds.closed {
  transform: translateY(100%);
}

.popUpAds .toggleButton {
  position: absolute;
  left: 10px;
  top: -38px !important;
  background-color: rgb(234, 234, 234);
  color: #374140;
  font-weight: bold;
  border: none;
  border-radius: 10px 10px 0 0;
  padding: 3px 20px;
  cursor: pointer;
}

.adContainerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.adContainer {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adContainer img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .adContainer {
      width: 90%;
      margin-left: -50px;
      object-fit: cover;
  }
}