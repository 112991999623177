.ay-dergi-carousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden; 
}

.ay-dergi-carousel-button {
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    background-color: #374140;
    color: white;
    padding: 20px 10px;
    margin-left: 5px;
    margin-right: 5px;
    transition: all 0.3s;
}

.ay-dergi-carousel-button:hover{
    background-color: #AE8D2F;
}

.ay-dergi-carousel-inner-wrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.ay-dergi-carousel-inner {
    display: flex;
    transition: transform 0.5s ease; 
    width: 100%; 
}

.ay-dergi-carousel-item {
    flex: 0 0 32.40%; 
    height: 450px;
    background-size: cover;
    background-position: center;
    position: relative;
    margin: 0 5px;
    display: flex;
    align-items: flex-end;
    box-shadow: rgba(0, 0, 0, 0.8) 0px -100px 36px -28px inset;
    transition: all 0.2s;
}

.ay-dergi-carousel-item:hover{
   scale: 1.04;
}

.ay-dergi-carousel-item-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
}

.ay-dergi-carousel-item-title {
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    padding: 10px;
    width: 100%;
    font-weight: 500;
    box-sizing: border-box;
    height: 3em; 
    /* text-align: center; */
    position: absolute;
    font-size: large;
    bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    line-height: 1.4em; 
}

@media (max-width: 1681px) {

    .ay-dergi-carousel-item{
        height: 400px;
        flex: 0 0 32.2%;
         
    }

    
}

@media (max-width: 1441px) {

    .ay-dergi-carousel-item{
        height: 350px;
        flex: 0 0 32.075%;
         
    }

    
}

@media (max-width: 1367px) {

    .ay-dergi-carousel-item{
        height: 320px;
        flex: 0 0 31.9%;
         
    }

    .ay-dergi-carousel-item-title{
        font-size: 14px;
    }
    
}

@media (max-width: 1281px) {

    .ay-dergi-carousel-item{
        height: 300px;
        flex: 0 0 31.8%;
         
    }

    .ay-dergi-carousel-item-title{
        font-size: 14px;
    }
    
}

@media (max-width: 1025px) {

    .ay-dergi-carousel-item{
        height: 300px;
        flex: 0 0 32%;
         
    }

    .ay-dergi-carousel-item-title{
        font-size: 14px;
    }
}

@media (max-width: 992px) {

    .ay-dergi-carousel-item{
        height: 320px;
        flex: 0 0 31.9%;
         
    }

    .ay-dergi-carousel-item-title{
        font-size: 16px;
    }
}

@media (max-width: 820px) {

    .ay-dergi-carousel-item{
        height: 250px;
        flex: 0 0 31.5%;
    }

    .ay-dergi-carousel-item-title{
        font-size: 13px;
    }

    .ay-dergi-carousel-button {
        font-size: 15px;
    }


}

@media (max-width: 769px) {

    .ay-dergi-carousel-item{
        height: 250px;
        flex: 0 0 31.2%;
    }

    .ay-dergi-carousel-item-title{
        font-size: 13px;
    }

    .ay-dergi-carousel-button {
        font-size: 15px;
    }


}

@media (max-width: 601px) {

    .ay-dergi-carousel-item{
        height: 220px;
        flex: 0 0 31.1%;
    }

    .ay-dergi-carousel-item-title{
        font-size: 11px;
    }

    .ay-dergi-carousel-button {
        font-size: 15px;
    }


}

@media (max-width: 480px)
{
    .ay-dergi-carousel-item{
        height: 480px;
        flex: 0 0 97.1%; 
    }
    .ay-dergi-carousel-web{
        display: none;
    }

    .ay-dergi-carousel-mobile{
        display: unset;
    }

    .ay-dergi-carousel-item-title{
        font-size: 18px;
    }

}

@media (max-width: 430px)
{
    .ay-dergi-carousel-item{
        height: 380px;
        flex: 0 0 96.5%; 
    }
    

}

@media (max-width: 400px)
{
    .ay-dergi-carousel-item{
        height: 370px;
        flex: 0 0 96.1%; 
    }
    

}

@media (max-width: 361px)
{
    .ay-dergi-carousel-item{
        height: 340px;
        flex: 0 0 95.7%; 
    }
    

}




