.special-news-card-slider-card {
  width: 100%;
  /* max-width: 445px; */
  height: auto;
}


.special-news-card-category-slider-btn {
  position: absolute;
  top: 3px;
  left: 3px;
  background-color: #E96F25;
  color: white;
  border: none;
  padding: 3px 7px;
  margin: 3px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
}

.special-news-card-title1 {
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #374140;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}


.special-news-card-sub-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.special-news-card-sub-card {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.3s ease;
}

.special-news-card-sub-card:hover {
  background-color: #374140;
  color: white;
}

.special-news-card-sub-card-image {
  width: 150px;
  height: 94px;
  object-fit: cover;
}

.special-news-card-sub-card-text {
  flex: 1;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    line-height: 1.4em; 
}

.special-news-card-slider-card .navigation {
  display: flex;
  margin-top: 15px;
  line-height: 1;
  justify-content: space-between;
}

.special-news-card-slider-card .navigation>div>span {
  padding: 8px 12px;
  display: inline-block;
  margin-right: 3px;
  cursor: pointer;
  transition: background-color 300ms, color 300ms;
  -moz-transition: background-color 300ms, color 300ms;
  -o-transition: background-color 300ms, color 300ms;
  -webkit-transition: background-color 300ms, color 300ms;
  background-color: #374140;
  color: white;
}
.more-link {
  padding: 8px 12px;
  background-color: #374140;
  color: white;
  text-decoration: none;
  font-weight: 600;
font-size: 14px;
transition: all 0.3s;
}

.more-link:hover {
  background-color: #AE8D2F;
}

.special-news-card-slider-card .navigation>div>span:hover {
  background-color: #AE8D2F;
}

.special-news-card-slider-card .navigation>div>span:hover i {
  color: #ffff;
}


@media (max-width: 1512px) {
  .special-news-card-slider-cards-content{
    height: 400px;
  }
  .special-news-card-slider-card{
    height: 400px;
  }
  .sub-card{
    /* border: solid red 1px !important; */
    height: 100px;
  }
  .special-news-card-sub-card-image{
    height: 70px;
    width: 115px;
  }
}

@media (max-width: 1280px) {
  .special-news-card-slider-cards-content{
    height: 360px;
  }
  .special-news-card-slider-card{
    height: 360px;
  }

  .special-news-card-sub-card-image{
    height: 60px;
    width: 100px ;
  }

  .special-news-card-slider-card .navigation>div>span{
    padding: 6px 8px;
  }

  .special-news-card-sub-card-text{
    font-size: 13px;
  }
}

/* @media (max-width: 1025px) {
 
  .special-news-card-sub-card-image{
    width: 50px ;
  }
} */

/* @media (max-width: 991px) {
  .special-news-card-sub-card-image{
    height: 80px;
    width: 120px ;
  }
} */
 

@media (max-width: 768px) {
  .special-news-card-slider-card {
    margin-top: 30px;
    /* width: 298px; */
    height: auto;
    margin-left: 0 !important;
  }

  .special-news-card-slider-cards-content {
    flex-direction: column !important;
  }

  .navigation {
    margin: 15px 7px 20px 0;
  }

 
}

